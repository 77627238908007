import React, { Component } from 'react';
import { AppStateConsumer } from '../../providers/AppState';
import api from '../../configs/api';
import Player from '../../components/Player/Player';
import PDFPreview from '../../components/PDFPreview/PDFPreview';

class Preview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fallback: this.props.src ? false : true,
      watermarkError: false
    }
  }

  get fallbackPreview() {
    let fallback = this.props.previewSettings.fallbackImages[this.props.type];
    let fallbackPreviewID;
    if (this.props.type==='asset') {
      fallbackPreviewID = fallback[this.props.domain] ? fallback[this.props.domain] : fallback['others'];
    } else {
      fallbackPreviewID = fallback.id;
    }

    return this.props.token ? api.getPrivatePreviewURL(fallbackPreviewID, this.props.token) : api.getPreviewURL(fallbackPreviewID);
  }

  handlePreviewError(error) {
    console.log(this.props);
    if (this.props.isWatermarked) {
      this.setState({
        watermarkError: true,
      });
    } else if (error && error.name !== 'NotAllowedError') {
      this.setState({
        fallback: true,
      });
    }
  }

  render() {
    if (this.state.watermarkError) {
      return <div className='ems-preview-fallback-not-active-error'>Watermarked image not available. Try again later.</div>
    }

    if(this.state.fallback) {
      if (this.props.previewSettings.fallbackImages.active) {
        return <img alt='preview' src={this.fallbackPreview}/>
      } else {
        return <div className='ems-preview-fallback-not-active-error'>Preview doesn't exist.</div>
      }
    }

    if(this.props.domain === 'video' || this.props.domain === 'audio') {
      return <Player
        token={this.props.token}
        share={this.props.share}
        onError={(error) => this.handlePreviewError(error)}
        previewUri={this.props.src}
        asset={this.props.asset}/>
    }

    if (this.props.domain === 'pdf' || this.props.domain === 'layout') {
      return <PDFPreview
        token={this.props.token}
        share={this.props.share}
        onError={(error) => this.handlePreviewError(error)}
        previewUri={this.props.src}
        asset={this.props.asset}
      />
    }

    return <img alt='preview' src={this.props.src} onError={(error) => this.handlePreviewError(error)}/>
  }
}

const ConnectedPreview = props => (
  <AppStateConsumer>
    {({ previewSettings }) => (
      <Preview
        {...props}
        previewSettings={previewSettings}
      />
    )}
  </AppStateConsumer>
);

export default ConnectedPreview;